import { useEffect } from "react";
import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import { useNavigate, useLocation } from "react-router";

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  useEffect(() => {
    if (route === "authenticated") {
      navigate("/admin");
    }
  }, [route, navigate, from]);

  return (
    <View className="auth-wrapper">
      <Authenticator className="py-5" />
    </View>
  );
}
